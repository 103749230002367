exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-awards-jsx": () => import("./../../../src/pages/awards.jsx" /* webpackChunkName: "component---src-pages-awards-jsx" */),
  "component---src-pages-course-information-jsx": () => import("./../../../src/pages/course-information.jsx" /* webpackChunkName: "component---src-pages-course-information-jsx" */),
  "component---src-pages-donations-jsx": () => import("./../../../src/pages/donations.jsx" /* webpackChunkName: "component---src-pages-donations-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-registration-jsx": () => import("./../../../src/pages/registration.jsx" /* webpackChunkName: "component---src-pages-registration-jsx" */),
  "component---src-pages-results-jsx": () => import("./../../../src/pages/results.jsx" /* webpackChunkName: "component---src-pages-results-jsx" */),
  "component---src-pages-sponsors-jsx": () => import("./../../../src/pages/sponsors.jsx" /* webpackChunkName: "component---src-pages-sponsors-jsx" */),
  "component---src-pages-volunteer-jsx": () => import("./../../../src/pages/volunteer.jsx" /* webpackChunkName: "component---src-pages-volunteer-jsx" */)
}

